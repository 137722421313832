
  export default {
    name: `MyCheckDefaultStatus`,
    props: {
      myCheck: {
        type: Object,
        default: undefined
      }
    }
  }
