

import worldCheckLogo from '~/components/marketplace/logos/WorldCheckLogo.png'
import MyCheckCreditReformStatus from '~/components/marketplace/my_checks/MyCheckCreditReformStatus.vue'

export default {
  name: `MyCheckWorldCheckStatus`,
  extends: MyCheckCreditReformStatus,
  data() {
    return {
      worldCheckLogo,
    }
  },
  methods: {
    getEndpoint() {
      return `world_check/select_candidate`
    },
    getPayload(noMatch=false) {
      return  noMatch ? { no_match_found: true } : { worldCheckId: this.selectedResult }
    },
    getFormattedDate(date) {
      return !!date ? this.$moment(date, `YYYY-MM-DD`).format(`DD.MM.YYYY`) : ``
    },
    capitalizeFirstChar(value) {
      const tLower = value?.toLowerCase() ?? ``
      return tLower.charAt(0).toUpperCase() + tLower.slice(1).toLowerCase()
    },
    selectResult(worldCheckId) {
      this.selectedResult = worldCheckId
    },
    getStrengthCode(value){
      switch (true) {
        case (value === 0):
          return `NONE`
        case (value < 25):
          return `LOW`
        case (value < 50):
          return `MEDIUM`
        case (value < 75):
          return `HIGH`
        case (value < 100):
          return `STRONG`
        case (value === 100):
          return `EXACT`
        default:
          return `UNDEFINED`
      }
    },
    getStrengthAttrs(strengthScore) {
      const backgroundColor = `grey lighten-4`
      return {
        "EXACT": {
          color: `green`,
          value: strengthScore ?? 100,
          backgroundColor
        },
        "STRONG": {
          color: `yellow`,
          value: strengthScore ?? 75,
          backgroundColor
        },
        "MEDIUM": {
          color: `orange`,
          value: strengthScore ?? 50,
          backgroundColor
        },
        "LOW": {
          color: `red`,
          value: strengthScore ?? 25,
          backgroundColor
        },
        "UNDEFINED": {
          value: strengthScore ?? 0,
          backgroundColor
        }
      }[this.getStrengthCode(strengthScore)]
    }
  }
}
