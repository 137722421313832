
import GenericDialog from '~/components/marketplace/my_checks/GenericDialog.vue'

export default {
  name: `MyCheckWorldCheckStatus`,
  components: { GenericDialog },
  data() {
    return {
      loadingNoMatch: false,
      loadingSelection: false,
      searchResults: null,
      showModal: [`in_progress`].includes(this.myCheck?.status.code),
      selectedResult: null
    }
  },
  props: {
    myCheck: {
      type: Object,
      default: undefined
    }
  },
  methods: {
    getPayload(noMatch=false) {
      return  noMatch ? { no_match_found: true } : { crefoID: this.selectedResult }
    },
    getEndpoint() {
      return `credit_reform/select_candidate`
    },
    submitSelection(event, noMatch=false) {
      this.loadingSelection = !noMatch
      this.loadingNoMatch = noMatch
      this.$api.marketplace.generateReport(this.myCheck.uuid, this.getPayload(noMatch), this.getEndpoint())
        .then(this.refreshCheck)
        .catch(this.showErrorMessage)
        .finally(this.reset)
    },
    selectResult(crefoID) {
      this.selectedResult = crefoID
    },
    refreshCheck(){
      this.$store.dispatch(`marketplace/getMyCheck`, this.myCheck.uuid)
    },
    getFormattedDate(date) {
      this.$moment.locale(`en`)
      return !!date
        ? this.$moment(date, `YYYY-MM-DD`).format(`DD.MM.YYYY`)
        : ``
    },
    showErrorMessage() {
      this.$flashMessage.error({
        title: this.$t(`messages.error`),
        message: this.$t(`messages.cant_generate_world_check_report`)
      })
    },
    reset() {
      this.loadingSelection = this.loadingNoMatch = this.showModal = false
    }
  }
}
